@font-face {
    font-family: 'Chalkduster';
    src: url('./fonts/Chalkduster.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'DigitalDisco';
    src: url('./fonts/DigitalDisco.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'Bauhaus';
    src: url('./fonts/BAUHS93.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}
/*!sc*/
@font-face {
    font-family: 'Clock';
    src: url('./fonts/digital-7.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}
/*!sc*/
@font-face {
    font-family: 'Taxicab';
    src: url('./fonts/Taxicab-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}
*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
}

html,
body {
    background: #000;
    /* overscroll-behavior-y: contain; */
    overscroll-behavior: none;
}

body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 300;
    background: #000;
    color: #fff;
    position: relative;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
}

.scrollable {
    width: 300px;
    /* adjust to your desired fixed width */
    overflow-x: scroll;
    height: 100vh;
}

body > div {
    display: block;
}

.inactive {
    display: none;
}

.arcade,
.tunnel {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 100px;
    right: 0;
    width: 100%;
    overflow: scroll;
}
.arcade img,
.tunnel img {
    display: flex;
    width: 200%;
    height: auto;
}

.countdown {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: #cc0000;
    text-transform: uppercase;
    background: linear-gradient(#313131, #0c0c0c);
    border-top: 2px solid #3a3a38;

    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}
.countdown::before {
    content: '';
    height: 25px;
    background: #2f2f2d;
    width: 100%;
    position: absolute;
    top: -27px;
    left: 0;
    bottom: 0;
}
.countdown .btn {
    font-family: Taxicab, sans-serif;
    background: #ffdd00;
    text-decoration: none;
    color: #000;
    font-weight: bold;
    border-radius: 30px;
    padding: 7px 20px;
    font-size: 0.9em;
    margin-left: auto;
    margin-right: auto;
}
.countdown .clock {
    font-family: Clock, sans-serif;
    font-size: 3em;
    margin-left: auto;
    margin-right: auto;
}

.controls {
    font-family: Chalkduster, sans-serif;
    position: absolute;
    left: 0;
    bottom: 130px;
    right: 0;
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    font-size: 1.3em;
}

.topControls {
    font-family: Chalkduster, sans-serif;
    position: absolute;
    left: 0;
    top: 130px;
    right: 0;
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    font-size: 1.3em;
}
.controls #arcade-cta {
    margin-left: auto;
}

.hint {
    position: absolute;
    right: 30px;
    top: 15px;
}
.hint span.link {
    margin-left: 6px;
    white-space: nowrap;
    font-size: 0.9em;
    opacity: 0.5;
}
.hint span.link svg {
    width: 10px;
    margin-right: 3px;
    fill: #fff;
}
.hint span.count {
    font-family: Clock, sans-serif;
    font-size: 1.1em;
    color: #a1ff9f;
}

aside {
    position: absolute;
    left: 0;
    top: 0; /* set these so Chrome doesn't return 'auto' from getComputedStyle */
    width: 200px;
    background: rgba(255, 255, 255, 0.66);
    border: 2px solid rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    padding: 8px;
}

video::-webkit-media-controls-fullscreen-button {
    display: none;
}
video::-webkit-media-controls-play-button {
    display: none;
}
video::-webkit-media-controls-timeline {
    display: none;
}
video::-webkit-media-controls-current-time-display {
    display: none;
}
video::-webkit-media-controls-time-remaining-display {
    display: none;
}
video::-webkit-media-controls-mute-button {
    display: none;
}
video::-webkit-media-controls-toggle-closed-captions-button {
    display: none;
}
video::-webkit-media-controls-volume-slider {
    display: none;
}

