@font-face {
    font-family: 'Chalkduster';
    src: url('./fonts/Chalkduster.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
} /*!sc*/
@font-face {
    font-family: 'Clock';
    src: url('./fonts/digital-7.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
} /*!sc*/
@font-face {
    font-family: 'Taxicab';
    src: url('./fonts/Taxicab-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
}
html,
body {
    background: #000;
}
body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 300;
    background: #000;
    color: #fff;
    position: relative;
    height: calc(var(--vh) * 100);
    -webkit-overflow-scrolling: touch;
}
.scrollable {
    width: 300px; /* adjust to your desired fixed width */
    overflow-x: scroll;
    height: calc(var(--vh) * 100);
}

body > div {
    display: block;
}

.inactive {
    display: none;
}

.wrapper {
    position: relative;
}

.start-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100vw;
    height: calc(var(--vh) * 100);
    position: relative;
}

.start-image {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../public/images/arcade.jpg');
    background-size: cover;
    background-position: center center;
}

.demo-logo-wrapper {
    z-index: 5;
    position: absolute;
    display: flex;
    justify-items: center;
    top: 0;
    @media (max-width: 600px) {
        margin-top: 12%;
    }
    @media (min-width: 600px) {
        top: 0;
        left: 0;
        margin-top: 2%;
        margin-left: 3%;
    }
}

.yellow-black {
    height: 10%;
    display: flex;
    flex: 1;
    align-content: stretch;
    width: 100%;
    z-index: 4;
}

.yellow-black-image {
    height: 100%;
    width: 50%;
    z-index: 3;
}

.start-inner-layer {
    height: 86%;
    margin-bottom: 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 96%;
    border: solid 2px #fff353;
    z-index: 3;
    @media (max-width: 600px) {
        padding-top: 20%;
        align-items: center;
    }
    @media (min-width: 600px) {
        height: 96%;
        margin-bottom: 0%;
    }
}

.intro-inner-layer {
    height: 86%;
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    width: 96%;
    border: solid 2px #fff353;
    z-index: 3;
    @media (min-width: 600px) {
        height: 96%;
        margin-bottom: 0%;
    }
    @media (min-width: 900px) {
        padding-top: 5%;
    }
}

.fifteen-minutes-wrapper {
    position: absolute;
    top: 0;
    left: 80%;
    width: 18vw;
    height: calc(var(--vh) * 18);
    margin-top: -10vw;
    @media (min-width: 600px) and (max-width: 1000px) {
        width: 9vw;
        height: calc(var(--vh) * 9);
        margin-top: -5vw;
    }
    @media (min-width: 1000px) {
        width: 6vw;
        height: calc(var(--vh) * 6);
        margin-top: calc(var(--vh) * -5);
    }
}

.black-opacity-layer {
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;
    background-color: black;
}

.sound-icon-top {
    position: absolute;
    top: 3em;
    left: 3em;
    z-index: 3;
    width: 5%;
    height: 5%;
    @media (max-width: 600px) {
        width: 10%;
        height: 10%;
        top: 1em;
        left: 1em;
    }
}

.sound-icon-wrapper {
    @media (max-width: 600px) {
        align-self: flex-start;
        justify-self: end;
        margin-left: 5%;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: 10px;
        width: 16%;
        height: 8%;
        z-index: 3;
    }

    @media (min-width: 600px) {
        position: absolute;
        bottom: 0;
        top: auto;
        width: 7%;
        height: 3%;
        left: 0;
        margin-left: 5%;
        margin-bottom: 5%;
    }
}

.top-metal-wrapper {
    height: 4%;
    width: 100%;
    z-index: 4;
}

.top-metal {
    height: 100%;
    width: 100%;
    object-fit: none;
}

.arcade-logo-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    z-index: 4;
    @media (min-width: 600px) {
        width: 42%;
    }
    @media (min-width: 800px) {
        width: 38%;
    }
    @media (min-width: 1200px) {
        width: 34%;
    }
    @media (min-width: 1600px) {
        width: 28%;
    }
}

.buttons-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
    gap: calc(var(--vh) * 3);
    align-items: center;
    flex-direction: column;
    @media (min-width: 600px) {
        flex-direction: row;
    }
}

.start-button-wrapper {
    height: 80px;
    border: solid;
    border-width: 2px;
    border-color: #fff353;
    border-radius: 40px;
    background: #4d226f;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
    width: 80%;
    @media (min-width: 600px) {
        width: 40%;
    }

    @media (min-width: 900px) {
        width: 30%;
    }

    @media (min-width: 1100px) {
        width: 25%;
    }
    @media (min-width: 1250px) {
        width: 20%;
    }
}

.demo-logo {
    width: 60vw;
    height: calc(var(--vh) * 18);
    @media (min-width: 600px) {
        width: 304px;
        height: 160px;
    }
}

.arcade,
.tunnel {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 100px;
    right: 0;
    width: 100%;
    overflow: scroll;
    cursor: grab;
    @media (min-width: 600px) {
        overflow: hidden;
    }
    img {
        -drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;

        pointer-events: none;
        display: flex;
        width: 250%;
        @media (min-width: 700px) {
            width: 130%;
        }
        @media (min-width: 1200px) {
            width: 110%;
        }
        @media (min-width: 1600px) {
            width: 100%;
        }
    }
}

.countdown {
    z-index: 30;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 77px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: #cc0000;
    text-transform: uppercase;
    background: linear-gradient(#313131, #0c0c0c);
    border-top: 2px solid #3a3a38;
    &::before {
        content: '';
        height: 25px;
        background: #2f2f2d;
        width: 100%;
        position: absolute;
        top: -27px;
        left: -0;
        bottom: 0;
    }
    .btn {
        font-family: Taxicab, sans-serif;
        background: #ffdd00;
        text-decoration: none;
        color: #000;
        font-weight: bold;
        border-radius: 30px;
        padding: 7px 20px;
        font-size: 0.9em;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        text-align: center;
        @media (min-width: 600px) {
            width: 25%;
        }
    }
    .clock {
        font-family: Clock, sans-serif;
        font-size: 3em;
        margin-left: auto;
        margin-right: auto;
    }
}

.screen-instructions {
    top: calc(var(--vh) * 22 - 102px);
    left: auto;
    right: auto;
    position: absolute;
    font-family: Chalkduster, sans-serif;
    font-size: 1.3em;
    z-index: 30;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    text-align: center;
    padding-left: 2%;
    padding-right: 2%;
    @media (min-width: 600px) {
        font-size: 1.5em;
        top: calc(var(--vh) * 24 - 80px);
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        text-align: center;
        &#rario-instructions {
            top: calc(var(--vh) * 14 - 80px);
        }
        &#placman-instructions {
            top: calc(var(--vh) * 15 - 80px);
            @media (max-width: 1500px) and (max-height: 800px) {
                left: 0;
                max-width: 400px;
                text-align: left;
            }
            @media (min-width: 920px) and (min-height: 600px) and (max-height: 800px) and (max-width: 1200px) {
                width: 300px;
            }
        }
        &#letris-instructions {
            top: calc(var(--vh) * 21 - 120px);
            @media (max-width: 1500px) and (max-height: 800px) {
                left: 0;
                max-width: 400px;
                text-align: left;
            }
        }
    }
}

.refresh {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    font-family: Chalkduster, sans-serif;
    font-size: 1.3em;
    bottom: 0;
    width: 175px;
    position: absolute;
    margin-left: auto;
    z-index: 30;
    margin-left: 25px;
    margin-right: 7%;
    margin-bottom: 30px;
    @media (min-width: 700px) {
        :hover {
            color: #ffdd00;
        }
        font-size: 1.5em;
        cursor: pointer;
    }
    @media (max-width: 900px) {
        width: 150px;
        right: 0;
        text-align: right;
    }
    @media (min-width: 900px) {
        margin-bottom: 9%;
        left: 0;
    }
}

.refresh:hover {
    color: #ffdd00;
}

.controls {
    font-family: Chalkduster, sans-serif;
    position: absolute;
    left: 0;
    bottom: 130px;
    right: 0;
    width: 50%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    font-size: 1.3em;
    @media (min-width: 600px) {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
        width: 25%;
        :hover {
            color: #ffdd00;
        }
    }
    #arcade-cta {
        cursor: pointer;
        margin-left: auto;
    }
    #back-cta {
        cursor: pointer;
        left: 0;
        width: 250px;
        margin-right: auto;
        text-align: left;
        @media (min-width: 600px) {
            font-size: 1.5em;
        }
    }
}

.screen-end {
    background-color: #22295b;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.screen-placman {
    background-color: #22295b;
    height: calc(var(--vh) * 100 - 100px);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media (min-width: 600px) {
        height: calc(var(--vh) * 100 - 100px);
    }
}

.screen-placman-wrapper {
    width: 100%;
    @media (min-width: 600px) {
        width: 500px;
        height: auto;
    }
}

.canvas-placman {
    @media (max-width: 600px) {
        width: 100%;
        margin-top: -40px;
    }
    @media (min-width: 600px) {
        width: 600px;
        height: auto;
    }
    @media (min-width: 920px) and (min-height: 600px) and (max-height: 800px) {
        width: 400px;
        height: 400px;
    }
}

.screen-letris {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(var(--vh) * 100 - 100px);
    background: linear-gradient(to bottom, #f6cb30, #118e36);
}

.screen-letris-bg {
    width: 100%;
    max-width: 600px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    img {
        width: 100%;
        height: auto;
    }
    @media (min-width: 920px) and (min-height: 600px) and (max-height: 800px) {
        width: 400px;
        height: 400px;
    }
}

.wrapper-rario {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #532075;
}

.screen-rario {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(var(--vh) * 100 - 100px);
    max-width: 600px;
    position: relative;
}

.screen-rario-bg {
    width: 95%;
    height: auto;
    @media (min-width: 600px) {
        width: 600px;
        height: auto;
    }
    @media (min-width: 500px) and (max-height: 850px) {
        width: 400px;
        height: auto;
    }
}

.rario-switch {
    position: absolute;
    z-index: 2;
    width: 64px;
    height: 64px;
    &.right {
        right: 25px;
        @media (max-width: 500px) {
            right: 15px;
            margin-top: -75px;
        }
        @media (min-width: 700px) and (max-width: 1000px) {
            right: 25px;
            margin-top: -100px;
        }
        @media (min-width: 1000px) {
            right: -30px;
            margin-top: -150px;
        }
    }
    &.left {
        left: 25px;
        svg {
            transform: scaleX(-1);
        }
        @media (max-width: 500px) {
            left: 15px;
            margin-top: -75px;
        }
        @media (min-width: 700px) and (max-width: 1000px) {
            left: 25px;
            margin-top: -100px;
        }
        @media (min-width: 1000px) {
            right: -30px;
            margin-top: -150px;
        }
    }
    @media (max-width: 700px) {
        right: 5px;
        width: 32px;
        height: 32px;
    }
    @media (min-width: 700px) and (max-width: 800px) {
        right: 10%;
        width: 48px;
        height: 48px;
    }
    animation: scaleAnimation 2s infinite;
}

@keyframes scaleAnimation {
    0% {
        transform: scale(0.9);
        transform: translateX(0);
    }
    50% {
        transform: scale(1.1);
        transform: translateX(-5px);
    }
    100% {
        transform: scale(0.9);
        transform: translateX(0);
    }
}

.hints-main-wrapper {
    overflow: hidden;
    cursor: grab;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    padding-top: calc(var(--vh) * 10);
    position: relative;
    display: flex;
    align-items: center;
}

.hints-inner-wrapper {
    width: 100%;
    height: 100%;
    padding-bottom: 5%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    scroll-behavior: smooth;
    display: flex;
    @media (min-width: 600px) {
        padding-bottom: 15%;
    }
}

.hint-wrapper {
    min-width: 60vw;
    height: calc(var(--vh) * 60);
    display: inline-block;
    margin-left: 7vw;
    margin-right: 7vw;
    font-family: Bauhaus;
    flex-direction: column;
    background-color: #4d236f;
    border: 3px solid #fff353;
    text-align: justify;
    @media (min-width: 600px) {
        min-width: 30vw;
        height: calc(var(--vh) * 50);
        margin-left: 5vw;
        margin-right: 5vw;
    }
    @media (min-width: 1040px) and (min-height: 600px) {
        font-size: 24px;
        text-wrap: balance;
    }
}

.hint-inner-wrapper {
    margin-left: 5vw;
    width: 25vw;
    border-bottom: 3px solid #fff353;
    @media (min-width: 600px) {
        width: 15vw;
    }
}

.hint-text {
    padding-top: calc(var(--vh) * 2);
    padding-left: calc(var(--vh) * 2);
    align-self: start;
    font-size: 28px;
}

.lock {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 20%;
    @media (min-width: 900px) and (min-height: 600px) {
        padding-top: 10%;
    }
}

.lock-inner {
    width: 60%;
    @media (min-width: 600px) {
        width: 30%;
        height: 30%;
    }
}

.hint-top-text {
    font-size: 36px;
}

.hint-top-text-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
}

.hint {
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    position: absolute;
    right: 30px;
    top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    @media (min-width: 600px) {
        color: rgb(246, 249, 247);
    }
    @media (min-width: 1000px) {
        scale: 145%;
        padding-right: 5%;
    }
    span.link {
        margin-left: 6px;
        white-space: nowrap;
        font-size: 0.9em;
        opacity: 1;
        display: flex;
        align-items: center;
        gap: 10px;
        svg {
            width: 10px;
            margin-right: 3px;
            fill: #f1f3ee;
        }
        @media (min-width: 600px) {
            font-size: 1.4em;
        }
    }
    span.count {
        font-family: Clock, sans-serif;
        font-size: 1.1em;
        color: #a1ff9f;
        @media (min-width: 600px) {
            font-size: 1.6em;
        }
    }
}

.info-button {
    font-family: Verdana, Geneva, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #5353ff;
    border: 2px solid white;
    color: white;
    cursor: pointer;
    width: 20px;
    height: 20px;
    font-size: 16px;
    @media (min-width: 600px) {
        width: 28px;
        height: 28px;

        font-size: 24px;
    }
}

.clickspots {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

.letris {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 25.3%;
    height: 12%;
    margin-top: 4.4%;
    @media (min-width: 600px) {
        cursor: pointer;
    }
}

.placman {
    position: absolute;
    left: 0;
    top: 49%;
    transform: translateY(-120%);
    width: 27%;
    height: 14%;
    margin-top: 0%;
    margin-left: 73%;
    opacity: 0.5;
    animation: placmanGlow 3.5s infinite;
    @media (min-width: 600px) {
        cursor: pointer;
    }
}

.rario {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-15%);
    width: 23%;
    height: 10%;
    margin-top: 0%;
    margin-left: 73%;
    opacity: 0.5;
    @media (min-width: 600px) {
        cursor: pointer;
    }
}

.door {
    position: absolute;
    left: 0;
    top: 100%;
    transform: translateY(-100%);
    width: 21%;
    height: 15%;
    margin-top: 0%;
    margin-left: 31%;
    opacity: 0.5;
    @media (min-width: 600px) {
        cursor: pointer;
    }
}

.newDoorOpen {
    position: absolute;
    left: 31%;
    right: 30%;
    bottom: 0;
    width: 38%;
    height: 13.3%;
    background: url('../public/images/doorOpen.jpg') no-repeat center center;
    background-size: 100%;
    @media (min-width: 600px) {
        cursor: pointer;
    }
}

.openDoor {
    position: absolute;
    left: 78%;
    bottom: 0;
    height: 13.3%;
    source,
    img {
        object-fit: contain;
        height: 100%;
        width: auto;
    }
}

.relative {
    position: relative;
}

.input-field {
    color: white;
    outline: none;
    height: calc(var(--vh) * 5);
    background: transparent;
    border: 0;
    border-bottom: 2px solid white;
    width: 50vw;
    text-align: center;
    font-weight: bold;
}

.keys-wrapper {
    padding-top: calc(var(--vh) * 12);
    box-sizing: border-box;
    width: 100%;
    z-index: 0;
    position: absolute;
    > div + div {
        margin-top: 30px;
    }
}

.keys-wrapper-large {
    padding-top: calc(var(--vh) * 12);
    padding-left: 25%;
    box-sizing: border-box;
    z-index: 0;
    position: absolute;
    > div + div {
        margin-top: 30px;
    }
}

.explain-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    width: 100%;
    position: relative;
    opacity: 0.9;
    @media (max-width: 600px) {
        height: calc(var(--vh) * 50);
        // padding-bottom: calc(var(--vh) * 10);
        border-bottom: 2px solid yellow;
        background-color: #0a0909;
    }
    @media (min-width: 600px) {
        border: 2px solid white;
        background-color: #1a1a1a;
        color: #e9dce0;
        height: calc(var(--vh) * 45);
        width: 70%;
    }
    @media (min-width: 900px) and (min-height: 900px) {
        height: calc(var(--vh) * 65);
    }
}

.explanation-arrow-down {
    display: block;
    position: absolute;
    font-size: 2em;
    bottom: 0;
    right: 15px;
    padding: 12px 16px;
    line-height: normal;
    animation: moveUpDown 2s infinite;
    @media (max-width: 600px) {
        right: 0;
    }
}

@keyframes moveUpDown {
    0%,
    100% {
        bottom: 0;
    }

    50% {
        bottom: 5px;
    }
}

.explain-text {
    height: 100%;
    color: #e9dce0;
    text-align: justify;
    overflow: auto;
    padding-left: 10vw;
    padding-right: 10vw;
    @media (min-width: 600px) {
        font-size: 32px;
    }
}

.explain-heading {
    font-family: 'Bauhaus';
    font-size: 48px;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 0px;
    text-underline-offset: 10px;
    @media (min-width: 600px) {
        font-size: 64px;
    }
}

.explain-section {
    padding-top: calc(var(--vh) * 1.5);
    padding-bottom: calc(var(--vh) * 1.5);

    height: 100%;
    color: #e9dce0;
    text-align: left;
    @media (max-width: 600px) {
        font-size: 24px;
    }
    @media (min-width: 600px) {
        font-size: 28px;
    }
    &.inline {
        padding-left: 3em;
        font-size: 24px;
        @media (max-width: 600px) {
            font-size: 20px;
            padding-left: 1em;
        }
    }
}

.explain-back {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    font-family: 'Bauhaus';
    color: #e9dce0;
    text-underline-offset: 10px;
    font-size: 48px;
    width: 80%;
    @media (min-width: 600px) {
        width: 50%;
    }
    @media (min-width: 800px) {
        width: 40%;
    }
    @media (min-width: 1100px) {
        width: 30%;
    }
    bottom: 0;
    right: 0;
    border: solid;
    border-width: 2px;
    border-radius: 40px;
    background: #a30e58;
}

.explanation-back-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    bottom: calc(var(--vh) * 2);
    width: 60%;
}

.text-explanation {
    z-index: 10;
    font-family: DigitalDisco;
    font-size: 48px;
    color: #e00046;
    text-underline-offset: 10px;
    cursor: pointer;
    @media (min-width: 600px) {
        margin-right: auto;
        transform: translateX(30px);
        left: 120px;
    }
}

.chronodecoder-slot {
    width: 60px;
    background-color: #1d1d1b;
    border-top: 2px solid #404039;
    border-left: 2px solid #56564b;
    border-right: 2px solid #33332e;
    @media (min-width: 400px) {
        width: 75px;
    }
    @media (min-width: 500px) {
        width: 80px;
    }
    @media (min-width: 550px) {
        width: 85px;
    }
    @media (min-width: 750px) {
        width: 90px;
    }
    @media (min-width: 850px) {
        width: 110px;
    }
    @media (min-width: 1000px) {
        width: 120px;
    }
}

.zero {
    position: absolute;
    left: 0;
    top: 0;
}
.glow {
    position: absolute;
    left: 0;
    top: 0;
    width: 250%;
    opacity: 1;
    animation: arcadeGlow 1.5s ease-in-out infinite alternate;
    fill: white;
    @media (min-width: 700px) {
        width: 120%;
    }
    @media (min-width: 1200px) {
        width: 110%;
    }
    @media (min-width: 1600px) {
        width: 100%;
    }
    &.placman {
        opacity: 0.5;
    }
}

@keyframes arcadeGlow {
    0% {
        filter: drop-shadow(0 0 0px #18b8ab);
    }
    50% {
        filter: drop-shadow(0 0 10px #c1da1b);
    }
    50% {
        filter: drop-shadow(0 0 0px #c1da1b);
    }
    100% {
        filter: drop-shadow(0 0 10px #ef6262);
    }
}

.text-none {
    text-decoration: none;
}

.canvas-end-wrapper {
    width: 100vw;
    height: calc(var(--vh) * 100);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 600px) {
        padding-top: 200px;
        padding-bottom: 200px;
    }
}

.canvas-end {
    border: solid;
    border-width: 2px;
    border-color: #fff353;
    border-radius: 40px;
    // max-width: 900px;
    // max-height: 1600px;
    // min-width: 300px;
    // min-height: 900px;
}

.share-button {
    margin: 48px;
    width: 400px;
    height: 80px;
    border: solid;
    border-width: 2px;
    border-color: #fff353;
    border-radius: 40px;
    background: #4d226f;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
    font-size: 75px;
    color: rgb(255, 243, 83);
    font-family: Bauhaus;
    @media (max-width: 600px) {
        margin-top: 24px;
        width: 225px;
        max-width: 100%;
        font-size: 50px;
    }
}
